import { isBrowser } from "@wisr/common";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { CreditProfileFormComponent } from "../../forms/credit-profile/credit-profile-form";
import { ErrorWidgetComponent } from "../../widgets/shared/error/error-widget.component";

export const CreateCreditProfilePage = () => {
  const { formValues } = useStaticQuery(graphql`
    query CreditCreateProfilePage {
      formValues {
        streetTypes {
          description
          common
          code
        }
      }
    }
  `);
  if (!formValues) {
    return <ErrorWidgetComponent />;
  }

  const { streetTypes } = formValues;

  if (!isBrowser()) {
    return null;
  }
  return <CreditProfileFormComponent streetTypes={streetTypes} />;
};
